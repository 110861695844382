import { reportMxMAnalytics } from "@roc-digital/mxm-base/logic";

export function reportSignupFromUtmUrl() {
  const utmUrl = localStorage.getItem('utm_url') || '';
  const utmReferrer = localStorage.getItem('utm_referrer') || '';
  const signupArticleId = localStorage.getItem('signup_article_id') || '';

  if(utmUrl || signupArticleId ) {
    reportMxMAnalytics({
      signupUtmUrl: utmUrl,
      utmReferrerUrl: utmReferrer,
      signupArticleId: signupArticleId,
    });
  
    localStorage.removeItem('utm_url');
    localStorage.removeItem('utm_referrer');
    localStorage.removeItem('signup_article_id');
  }
}

export function logUtmParameter() {
  const search = window.location.search;
  if(search.includes("?utm") || search.includes("&utm")) {
    localStorage.setItem('utm_url', window.location.toString());
    localStorage.setItem('utm_referrer', document.referrer || '');
  }
}

export function logSignupArticleId(articleId: string) {
  localStorage.setItem('signup_article_id', articleId || '');
}