import React from "react";

export function createState<T extends object>(defaultValue: T) {
  const STATE: T = {
    ...defaultValue
  };
  const EVENTS = new EventTarget();
  const set = (changes: Partial<T>) => {
    Object.assign(STATE, changes);
    EVENTS.dispatchEvent(new Event('next'));
  }
  const get = () => STATE;
  const use = () => {
    const [current, setNext] = React.useState(STATE);

    React.useEffect(() => {
      const cb = () => {
        setNext({...STATE});
      };
  
      EVENTS.addEventListener('next', cb);
  
      return () => {
        EVENTS.removeEventListener('next', cb);
      }
    });
  
    return current;
  }

  return {get, set, use};
}