const findTokensRegExp = /{{\s*(\w+)\s*}}/gm;
export function findTextTokens(node, list) {
    list = list || [];
    if (node.element === 'paragraph' || node.element === 'text') {
        const data = node.data;
        if (typeof data?.html !== 'string')
            return list;
        findTokensRegExp.lastIndex = 0;
        let matches = findTokensRegExp.exec(data.html);
        while (matches) {
            list.push(matches[1]);
            matches = findTokensRegExp.exec(data.html);
        }
    }
    else if (node.children?.length) {
        node.children.forEach(n => findTextTokens(n, list));
    }
    return list;
}
export function replaceTextTokens(html, tokens) {
    if (!tokens)
        return html;
    return html.replaceAll(findTokensRegExp, (...args) => {
        const token = args[1];
        return tokens[token] || tokens['*'] || 'Unknown';
    });
}
