import { JsonFetch } from "@roc-digital/mxm-base/logic";
import { EmailArticleOptions } from "@roc-digital/types";
import { getEnv } from "./env";

export async function emailArticle(options: EmailArticleOptions) {
  JsonFetch({
    method: 'POST',
    url: getEnv().auxApi + '/articles/share-in-email',
    data: options
  })
}