import { useEffect, useMemo } from 'react';
import { ArticleSchema, ViewSchema, ZoneSchema } from '@roc-digital/mxm-base/data';
import { ClickEvent, useMethod, mapToArticleContent, getArticleActionHandler, useProfile } from '@roc-digital/ui-lib';
import { Loading } from '@/components/elements/Loading';
import { Modal, ZoneLayout } from '@roc-digital/ui-web';
import { listArticlesByScopesV2, handleVote, handleBookmark } from '@roc-digital/mxm-base/logic';
import { useNavigate } from 'react-router';
import { toast } from 'react-toastify';
import LoginSignUpPrompt from '@/components/elements/LoginSignupPrompt';
import { useAuthState } from '@/logic/firebase';
import SubscribeBanner from '@/components/elements/SubscribeBanner';
import { shareArticle } from '@/logic/shareArticle';

export interface ZonePreviewProps {
  preview?: boolean;
  zone: ZoneSchema;
  view: ViewSchema;
  editZoneEvent?: ClickEvent;
  deleteZoneEvent?: ClickEvent;
  hideTags?: boolean;
  hideMeta?: boolean;
}

export function ZonePreview (props: ZonePreviewProps) {
  const navigate = useNavigate();
  const layoutId = props.zone.web_layout;
  const articlesQuery = useMethod(listArticlesByScopesV2, {defaultValue: props.zone?.articles});
  const auth = useAuthState();
  const loggedOut = !auth.authenticated;
  const profile = useProfile(true);
  const showPaywall = props.zone?.pay_wall && !profile?.subscribed;
  
  const onAction = useMemo(() => {
    return getArticleActionHandler({
      get: articlesQuery.getLastResult,
      set: articlesQuery.setResult,
      navigate: async (article) => navigate(`/article/${article.id}?${createSlug(article.title)}`),
      bookmark: async (article) => {
        if (loggedOut) { 
          openSignupPrompt(); 
          return article;
        } else {
          try {
            const result = await handleBookmark(article);
            if(result.bookmarked) {
              toast.success('Article bookmarked');
            } else {
              toast.success('Bookmark removed');
            }
            return result;
          } catch(err) {
            console.error(err);
            toast.error('Failed to bookmark article')
            return article;
          }
        }
      },
      vote: (article, vote) => {
        if (loggedOut) {
          return loggedoutVote(article); 
        } else {
          return handleVote(article.id, vote);
        }
      }, 
      share: (article) => openShareModal(article)
    });
  }, []);

  const createSlug = (title: string) => {
    const lowercaseText = title.toLowerCase();
    const formattedText = lowercaseText.replace(/[^a-z0-9]+/g, '-');
    const slug = formattedText.replace(/^-+|-+$/g, '');
    return slug;
  };

  const loggedoutVote = async(article: ArticleSchema) => {
    openSignupPrompt(); 
    return article;
  }; 

  const openSignupPrompt = () => {
    Modal.open('mxm.modal', <LoginSignUpPrompt/>, 'small');
  };

  const openShareModal = (article: ArticleSchema) => {
    shareArticle(article);
  };

  const articles = useMemo(() => {
    if(!articlesQuery.result) return [];

    return articlesQuery.result.map(a => mapToArticleContent(a, {}));
  }, [articlesQuery.result]);

  useEffect(() => {
    if(props.zone?.articles?.length) {
      articlesQuery.setResult(props.zone?.articles);
    } else {
      const scopes = props.zone?.tags?.map(z => z.scope) as string[] || [];
      articlesQuery.run(scopes, 1, 9);
    }
  }, [props.zone]);

  return (
    <div className="w-full flex flex-col max-w-5xl mx-auto px-2 relative">
      {articlesQuery.loading && <Loading customClassName={'mb-8 ml-auto mr-auto'} />}
      {articles && (
        <ZoneLayout
          layoutId={layoutId as any}
          articles={articles}
          onAction={onAction}
          hideTags={props.hideTags}
          hideMeta={props.hideMeta}
        />
      )}
      {showPaywall ? <>
        <div style={{
          position: 'absolute',
          height: '100%',
          width: '100%',
          backdropFilter: 'blur(3px)',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          padding: '100px',
        }}>
          
          <SubscribeBanner message='Subscribe to MxM News+ to access this content.'/>
        </div>
      </> : null}
    </div>
  );
}

export default ZonePreview;
