import { RuiReactComponents } from '@roc-digital/rich-ui';

import { GenericElement } from './GenericElement';
import { ContainerElement } from './Container';
import { ParagraphElement } from './Paragraph';
import { ButtonElement } from './Button';
import { EmbedElement } from './Embed';

export function getWebComponent(): RuiReactComponents {
  return {
    'container': ContainerElement,
    'paragraph': ParagraphElement,
    'text': ParagraphElement,
    'button': ButtonElement,
    'embed': EmbedElement,
    '*': GenericElement,
  }
}