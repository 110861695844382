export function computeCssSizing(props, parentDir) {
    const style = {
        display: 'flex',
        flexDirection: props.direction === 'row' ? 'row' : 'column',
    };
    if (parentDir === 'row') {
        if (props.width === 'fill') {
            style.width = undefined;
            style.flex = 1;
        }
        else if (props.width === 'hug') {
            style.width = undefined;
            style.flex = 0;
        }
        else if (typeof props.width === 'number') {
            style.flex = undefined;
            style.width = props.width + 'px';
        }
        if (props.height === 'fill') {
            style.alignSelf = 'stretch';
            style.height = undefined;
            if (props.maxHeight) {
                style.height = '100%';
            }
            else {
                style.height = undefined;
            }
        }
        else if (props.height === 'hug') {
            style.alignSelf = undefined;
            style.height = 'fit-content';
        }
        else if (typeof props.height === 'number') {
            style.alignSelf = undefined;
            style.height = props.height + 'px';
        }
    }
    else {
        if (props.height === 'fill') {
            style.height = undefined;
            style.flex = 1;
        }
        else if (props.height === 'hug') {
            style.height = undefined;
            style.flex = 0;
        }
        else if (typeof props.height === 'number') {
            style.flex = undefined;
            style.height = props.height + 'px';
        }
        if (props.width === 'fill') {
            if (props.maxWidth) {
                style.alignSelf = 'unset';
                style.width = '100%';
            }
            else {
                style.alignSelf = 'stretch';
                style.width = undefined;
            }
        }
        else if (props.width === 'hug') {
            style.alignSelf = undefined;
            style.width = 'fit-content';
        }
        else if (typeof props.width === 'number') {
            style.alignSelf = undefined;
            style.width = props.width + 'px';
        }
    }
    return style;
}
