import { User, UserSchema } from "@roc-digital/mxm-base/data";
import { publish, subscribe, unsubscribe } from "@roc-digital/mxm-base/events";
import { readProfile } from "@roc-digital/mxm-base/logic";
import React from "react";

const state = {
  hasCalled: false,
  current: null as User | null,
}

export function setSubscribed() {
  if(!state.current) return;
  state.current.subscribed = true;
  publish('mxm', 'user-profile', {...state.current});
}

export function loadProfile() {
  if(!state.hasCalled) {
    state.hasCalled = true;
    readProfile()
    .then((user) => {
      state.current = user as any;
      if(user?.id === 'anonymous') {
        state.hasCalled = false;
      }
    })
    .catch((err) => {
      state.hasCalled = false;
      console.error(err);
    });
  }
}

export function setProfile(profile: UserSchema | undefined) {
  if(profile?.id !== 'anonymous') {
    state.current = profile as any;
    state.hasCalled = true;
  }
}

export function useProfile(noAutoLoad?: boolean) {
  const [profile, setProfile] = React.useState(() => {

    if(!noAutoLoad) {
      loadProfile();
    }

    if(state.current) {
      return state.current;
    }

    return null;
  });

  React.useEffect(() => {
    const key = subscribe('mxm', 'user-profile', (event) => {
      //@ts-ignore
      if(event?.data?.id === 'anonymous') return;
      setProfile(event?.data || null as any);
    })

    return () => {
      unsubscribe('mxm', 'user-profile', key)
    }
  }, []);

  return profile
}

export function getProfile() {
  return state.current || null;
}