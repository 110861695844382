import React, { memo, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { handleBookmark, handleVote, readArticleV2 } from '@roc-digital/mxm-base/logic';
import { Heading1, Icon, Loading, Rumble, Modal, ArticleView, Button, ButtonSizes } from '@roc-digital/ui-web';
import { shareIcon, mapToArticleContent, useMethod, getArticleActionHandler, useProfile } from '@roc-digital/ui-lib';
import { TwitterTweetEmbed } from 'react-twitter-embed';
import { mxmIcon } from '@roc-digital/ui-lib';
import YouTube from 'react-youtube';
import AppFooter from '@/components/elements/AppFooter';
import LoginSignup from '@/components/elements/LoginSignup';
import ConfirmNavigateAway from '@/components/elements/ConfirmNavigateAway';
import { toast } from 'react-toastify';
import { useAuthState } from '@/logic/firebase';
import RelatedArticles from '@/components/elements/RelatedArticles';
import UpdateShareMeta from '@/components/elements/UpdateShareMeta';
import MobileSnackbar from '@/components/elements/MobileSnackbar';
import SubscribeBanner from '@/components/elements/SubscribeBanner';
import { shareArticle } from '@/logic/shareArticle';
import { isArticleUnlocked } from '@/logic/article-unlock-token';
import { logSignupArticleId } from '@/logic/analytics';

const Article = memo(() => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const articleQuery = useMethod(readArticleV2, {autoRun: [id || ''], ssrPreloadKey: 'article_view:'+id});
  const articleData = articleQuery.result;
  const loading = articleQuery.loading;
  const auth = useAuthState();
  const profile = useProfile();
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  React.useEffect(() => {
    if(articleQuery.result?.id) logSignupArticleId(articleQuery.result?.id);
  }, [articleQuery.result?.id])

  function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height
    };
  }

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const openModal = () => {
    if (articleData) {
      shareArticle(articleData)
    }
  };

  const onAction = useMemo(() => {
    return getArticleActionHandler({
      get: () => [articleQuery.getLastResult()],
      set: (articles) => articleQuery.setResult(articles[0]),
      navigate: async (article) => navigate(`/article/${article.id}?${createSlug(article.title)}`),
      bookmark: async (article) => {
        try {
          const result = await handleBookmark(article);
          if(result.bookmarked) {
            toast.success('Article bookmarked');
          } else {
            toast.success('Bookmark removed');
          }
          return result;
        } catch(err) {
          console.error(err);
          toast.error('Failed to bookmark article')
          return article;
        }
      },
      vote: (article, vote) =>  handleVote(article.id, vote),
      share: (article) => {
        openModal();
      }
    });
  }, []);

  const createSlug = (title: string) => {
    const lowercaseText = title.toLowerCase();
    const formattedText = lowercaseText.replace(/[^a-z0-9]+/g, '-');
    const slug = formattedText.replace(/^-+|-+$/g, '');
    return slug;
  };

  const formatDate = (date: any) => {
    if (date) {
      return new Date(date * 1000).toDateString();
    } else {
      return '';
    }
  };

  if (!articleData) {
    return <Loading />;
  }

  const articleItem = mapToArticleContent(articleData, {}, true);

  const openOriginalArticle = (articleItem: any) => {
    Modal.open('mxm.modal', <ConfirmNavigateAway 
      link={articleItem.url}
      onCancel={() => Modal.close('mxm.modal')}
      onContinue={() => {
        Modal.close('mxm.modal');
        window.open(articleItem.url, "_blank");
      }}
    />, 'medium');
  }

  let renderArticle = (
    <ArticleView orientation='landscape' size='lg' article={articleItem} onAction={onAction}> 
      <div className="md:w-2/3 lg:w-2/3 xl:w-2/3 mt-4">
        <Button size={ButtonSizes.medium} customClassName='max-h-[]' clickEvent={() => openOriginalArticle(articleItem)}>
          <span className='hidden-xs'>Continue to Article</span>
          <span className='visible-xs'>Read Article</span>
        </Button>
      </div>
    </ArticleView>
  );

  const mxmExclusiveLabel = <div className="text-s ml-2 capitalize py-0 px-2 rounded-full bg-gray-100 dark:bg-gray-500 mr-4 dark:text-white">MXM Exclusive</div>;
  const tagLabel = (label: string = 'General News') => {
    return <div className="text-s ml-2 capitalize py-0 px-2 rounded-full bg-gray-100 dark:bg-gray-500 mr-4 dark:text-white">
      {label}
    </div>;
  };

  const url = articleData?.url || '';
  const share = <span onClick={() => openModal()}><Icon size="medium" src={shareIcon} className="cursor-pointer ml-3" title="Share this content"/></span>;
  const isTweet = () => {
    const regex = /\b(https:\/\/x\.com|https:\/\/twitter\.com)\/\S*/i; ///(^|\s)(https:\/\/)?(x\.com|twitter\.com\/\w+\/status\/\d+)($|\s)/i;
    return regex.test(url);
  };
  const isYoutube = url?.includes('youtube.com');
  const isRumble = url?.includes('rumble.com');

  const getLogoName = (newTags: any) => {
    let logo = '';
    if (newTags && newTags.length > 0) {
      logo =
      newTags.find((tag: any) => tag.image_url !== undefined && tag.image_url !== '' && tag.image_url !== null)
          ?.image_url || '';
    }
    return logo || '/icons/article.svg';
  };

  const getEmbedId = (url: string) => {
    // console.log(url);
    if (isTweet()) {
      const regex = /\/(\d+)(?:\?s=\d+)?$/;
      const tweetId: any = url.match(regex);
      return tweetId[1];
    }
    if (isYoutube) {
      const regex = /(?:youtube\.com\/(?:embed\/|watch\?v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/;
      const videoId: any = url.match(regex);
      return videoId[1];
    }
    if (isRumble) {
      const urlSections = url?.split('/');
      return urlSections[4];
    }
  };

  const applyPaywall = () => {
    if (isArticleUnlocked(articleData?.id)) return false;
    let paid = profile?.subscribed ? true : false; // Temp give a paid account flag to all logged in users

    // console.log(paid);

    if (!articleData.paywall) { return false; }
    switch(articleData?.paywall) {
      case 'free':
        return false;
      case 'authorized':
        return (!auth.authenticated ? true : false);
      case 'paid': // will return true if an article is PAID and a user is either not authenticated or not paid
        return (!auth.authenticated || !paid ? true : false);
      default:
        return true;
    }
  }
  const paywallAction = () => {
    if (isArticleUnlocked(articleData?.id)) return null;
    let wallType = articleData?.paywall || 'free';
    //console.log(wallType, auth);
    
    if(wallType === 'authorized' && !auth.authenticated){
      return <div className="dark:text-white relative">
        <div style={{position: 'absolute', top: '-124px', height: '100px', width: '100%', zIndex: 1, backgroundImage: 'linear-gradient(rgba(0,0,0,0), white)'}}></div>
        <div className='font-chivo text-lg font-bold mb-2 text-center'>This article requires an account.</div>
        <div className='text-center'>
          <div>Create your account today and access Exclusive Content<br/> and customize your news based on your interests!</div>
          <div className='mt-3 mb-8'>
            <Button size={ButtonSizes.medium} clickEvent={() => navigate('/login')}>Login</Button> Or &nbsp;
            <Button size={ButtonSizes.medium} clickEvent={() => navigate('/signup')}>Sign Up</Button>
          </div>
        </div>
      </div>
    }

    if(wallType === 'paid' && !profile?.subscribed){
      return <div className="dark:text-white relative">
        <div style={{position: 'absolute', top: '-124px', height: '100px', width: '100%', zIndex: 1, backgroundImage: 'linear-gradient(rgba(0,0,0,0), white)'}}></div>
        <div className='pb-6 text-center'>
          <div className='font-chivo text-lg font-bold mb-2'>This article requires a paid account.</div>
          <div>SIGNUP TODAY and access Exclusive Paid Content<br/> and Unlimited Local News from over 200 US cities!</div>
          <div className='mt-5'>
            <Button customClassName='!w-[200px]' size={ButtonSizes.large} clickEvent={() => navigate('/account/billing')}>Subscribe Now</Button>
          </div>
        </div>
      </div>
    }

    return null;
  };

  if (isRumble || isTweet() || isYoutube) {
    renderArticle = (
    <>
      {windowDimensions.width > 650 ? <>
        <div className={'flex w-full gap-x-4 bg-white dark:bg-surface-dark-low shadow-md mt-4 relative'}>{/** Hidden XS */}
        {loading ? <Loading/> : <></>}
        <div className={'w-1/2 h-full flex items-center'}>
          {isRumble && <Rumble  key={articleData.id} id={getEmbedId(url) as string} />}
          {isTweet() && 
            <div className="w-full -mt-2 -mb-2">
              <TwitterTweetEmbed key={articleData.id} tweetId={getEmbedId(url) as string} options={{ height: 300 }}/>
            </div>
          }
          {isYoutube && <YouTube key={articleData.id} videoId={getEmbedId(url) as string} opts={{ height: '550px', width: '100%', playerVars: { autoplay: 0, }, }} className="w-full"/>}
        </div>
        <span className={'w-1/2 flex flex-col px-2'}>
          <Heading1
            className={'text-[#1E222A] !font-extrabold !text-[32px] !leading-9 !tracking-tight !font-chivo mb-2 mt-6 dark:text-white'}
          >
            {articleData.title}
          </Heading1>
          <div className="w-full flex items-center justify-between mb-4">
            {articleData.content_type === 'mxm_exclusive' ? 
              <>
                <span>
                  <Icon src={mxmIcon} size="large" />
                  {share}
                </span>
                {tagLabel('MXM Exclusive')}
              </> 
            :
              <>
                <div className="flex items-center">
                  <img src={getLogoName(articleData.tags)} className='mr-2 max-h-6 mt-1'/>
                  {share}
                </div>
                {tagLabel(articleItem.tag)}
              </>
            }
          </div>
          <span dangerouslySetInnerHTML={{ __html: articleData?.content as string }} className={`article-content-styling dark:text-white mb-6 ${applyPaywall() ? 'max-h-60 overflow-hidden' : ''}`}></span>
          {paywallAction()}
        </span>
      </div>
      </> : <>
      <div className={'flex flex-wrap w-full bg-white dark:bg-surface-dark-low relative'}>
        <div className={'w-full h-full block text-center'}>
          {isRumble && <Rumble id={getEmbedId(url) as string} />}
          {isTweet() && <TwitterTweetEmbed tweetId={getEmbedId(url) as string} options={{ height: 300 }} />}
          {isYoutube && <YouTube videoId={getEmbedId(url) as string} opts={{ height: '390', width: '100%', playerVars: { autoplay: 0, }, }} />}
        </div>
        <span className={'w-full flex flex-col p-2'}>
          <Heading1
            className={'text-[#1E222A] !font-extrabold !text-[32px] !leading-9 !tracking-tight !font-chivo mb-4 mt-4 dark:text-white'}
          >
            {articleData.title}
          </Heading1>
          <div className="w-full flex items-center justify-between mb-4">
            <span>
              <Icon src={mxmIcon} size="medium" />
              {share}
            </span>
            <div className="text-xs ml-2 capitalize border py-0 px-1 rounded-full bg-gray-100">MXM Exclusive</div>
          </div>
          <span dangerouslySetInnerHTML={{ __html: articleData?.content as string }} className={`article-content-styling dark:text-white mb-6 ${applyPaywall() ? 'max-h-60 overflow-hidden' : ''}`}></span>
          {paywallAction()}
        </span>
      </div>
      </>}
      
      
    </>
    );
  } else if (articleData.content_type === 'mxm_exclusive' || articleData.content_type === 'mxm_ai') {
    renderArticle = (
      <>
        <div className={'w-full bg-white dark:bg-surface-dark-low hidden-xs shadow-md mt-4 relative'}>
          <div className={'w-full flex bg-gray-200 dark:bg-black relative overflow-hidden'}>
            <img alt={'article url'} src={articleData.image_url ? articleData.image_url : '/photos/no-image.svg'} className="mx-auto z-[1] max-h-[300px]"/>
            <div className={`opacity-75 absolute h-full w-full bg-cover bg-center blur-lg`} style={{backgroundImage: `url(${articleData?.image_url})`}}></div>
          </div>
          <span className={'w-full flex flex-col px-8'}>
            <Heading1
              className={'text-[#1E222A] !font-extrabold !text-[32px] !leading-9 !tracking-tight !font-chivo mb-4 mt-6 dark:text-white'}
            >
              {articleData.title}
            </Heading1>
            <div className="text-sm pb-2 dark:text-gray-400">{formatDate(articleData.date)}</div>
            <div className="w-full flex items-center justify-between mb-4">
              <span>
                <Icon src={mxmIcon} size="large" />
                {share}
              </span>
              {mxmExclusiveLabel}
            </div>
            <span dangerouslySetInnerHTML={{ __html: articleData?.content as string }} className={`article-content-styling dark:text-white mb-6 ${applyPaywall() ? 'max-h-60 overflow-hidden' : ''}`}></span>
            {paywallAction()}
          </span>
        </div>
        <div className={'flex w-full gap-x-4 bg-white dark:bg-surface-dark-low visible-xs shadow-md relative'}>
          <span className={'w-full'}>
            <img alt={'article url'} src={articleData.image_url ? articleData.image_url : '/photos/no-image.svg'} />
          </span>
          <span className={'w-full flex flex-col px-4'}>
            <Heading1
              className={'text-[#1E222A] !font-extrabold !text-[32px] !leading-9 !tracking-tight !font-chivo mb-4 mt-6 dark:text-white'}
            >
              {articleData.title}
            </Heading1>
            <div className="text-sm pb-2 dark:text-gray-400">{formatDate(articleData.date)}</div>
            <div className="w-full flex items-center justify-between mb-4">
              <span>
                <Icon src={mxmIcon} size="large" />
                {share}
              </span>
              {mxmExclusiveLabel}
            </div>
            <div dangerouslySetInnerHTML={{ __html: (articleData?.content) as string }} className={`article-content-styling dark:text-white mb-6 ${applyPaywall() ? 'max-h-60 overflow-hidden' : ''}`}></div>
            {paywallAction()}
          </span>
        </div>
      </>
    );
  }

  return (
    <>
      <UpdateShareMeta 
        title={articleData.title} 
        description={
          articleData.content ? 
            (articleData.content.length > 120 ? articleData.content.slice(0,120) + '...' : articleData.content)
            : undefined
        }
        image_url={articleData.image_url ? articleData.image_url : undefined}
      />
      <div className="h-full w-full flex flex-col justify-center mx-auto pt-4 dark:bg-surface-dark ">
        <div className="w-full flex flex-col xl:mb-12 max-w-5xl mx-auto px-2 mb-8">
          <>{renderArticle}</>
        </div>
        {!auth.authenticated ? 
          <LoginSignup /> : 
          <>
            {!profile?.subscribed ? <SubscribeBanner size='small' message='Get MxM News+ for more exclusive content!'/> : null}
          </> 
        }
        <div className="w-full flex flex-col xl:mb-12 max-w-5xl mx-auto mb-8">
          <Heading1 className="dark:text-white mx-2 my-6 text-action !font-extrabold !text-[32px] !leading-9 !tracking-tight !font-chivo">
            Other Recent Articles
          </Heading1>
          <RelatedArticles tags={articleData?.tags} articleId={articleData.id}/>
        </div>
      </div>
      <MobileSnackbar />
      <AppFooter />
    </>
  );
});

Article.displayName = 'MyMxM';

export default Article;
