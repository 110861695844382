import { CSSProperties } from 'react';
import { RuiReactComponentProps, ContainerAttributes } from '@roc-digital/rich-ui';
import { computeCssAlignment } from '@roc-digital/rich-ui/dist/utils/computeCssAlignment';
import { computeCssSizing } from '@roc-digital/rich-ui/dist/utils/computeCssSizing';

export function ContainerElement(props: RuiReactComponentProps) {
  const style = (props.node?.data || {}) as ContainerAttributes;
  const parentDir = props.parent?.data?.direction === 'row' ? 'row' : 'column';
  return <div ref={(e) => applyLayoutToElement(style,  e as any, parentDir)}>
    {props.children}
  </div>;
}

export function applyLayoutToElement(layout: ContainerAttributes, element: HTMLElement, parentDir: 'row' | 'column') {
  if(!element) return;

  const style = element.style;
  style.display = 'flex';
  style.flexDirection = layout.direction === 'row' ? 'row' : 'column';
  style.width = layout.width + 'px';
  style.height = layout.height + 'px';
  style.gap = layout.gap + 'px';
  style.paddingLeft = layout.paddingLeft + 'px';
  style.paddingRight = layout.paddingLeft + 'px';
  style.paddingTop = layout.paddingTop + 'px';
  style.paddingBottom = layout.paddingTop + 'px';
  style.marginLeft = layout.marginLeft + 'px';
  style.marginRight = layout.marginRight + 'px';
  style.marginTop = layout.marginTop + 'px';
  style.marginBottom = layout.marginBottom + 'px';
  if(layout.backgroundColor !== 'none') {
    style.backgroundColor = layout.backgroundColor || 'unset';
  } else {
    style.backgroundColor = 'unset';
  }
  style.borderRadius = (layout.borderRadius || 0) + 'px';

  style.minWidth =  layout.minWidth  ? (layout.minWidth + 'px') : 'unset';
  style.maxWidth =  layout.maxWidth  ? (layout.maxWidth + 'px') : 'unset';
  style.minHeight =  layout.minHeight  ? (layout.minHeight + 'px') : 'unset';
  style.maxHeight =  layout.maxHeight  ? (layout.maxHeight + 'px') : 'unset';

  if(layout.backgroundImage) {
    const imageFit = layout.imageFit || 'center';
    style.backgroundImage = `url("${layout.backgroundImage}")`;

    if(imageFit === 'cover') {
      style.backgroundSize = 'cover';
      style.backgroundRepeat = 'no-repeat';
      style.backgroundPosition = 'unset';
    } else if(imageFit === 'contain') {
      style.backgroundSize = 'contain';
      style.backgroundRepeat = 'no-repeat';
      style.backgroundPosition = 'unset';
    } else if(imageFit === 'center') {
      style.backgroundSize = 'contain';
      style.backgroundRepeat = 'no-repeat';
      style.backgroundPosition = 'center';
    } else if(imageFit === 'repeat') {
      style.backgroundSize = 'contain';
      style.backgroundRepeat = 'repeat';
      style.backgroundPosition = 'unset';
    } else if(imageFit === 'stretch') {
      style.backgroundSize = 'auto';
      style.backgroundRepeat = 'round';
      style.backgroundPosition = 'unset';
    }

  } else {
    style.backgroundImage = 'unset';
    style.backgroundSize = 'unset';
  }

  const sizing = {
    ...computeCssSizing(layout, parentDir),
    ...computeCssAlignment(layout),
  }
  Object.keys(sizing).forEach((key) => {
    let value = sizing[key as keyof CSSProperties];
    if(value === undefined) value = 'unset';
    //@ts-ignore
    style[key] = value;
  })
}