export function computeCssAlignment(props) {
    const style = {
        display: 'flex',
        flexDirection: props.direction === 'row' ? 'row' : 'column',
    };
    let vPos = 'start';
    let hPos = 'start';
    if (props.alignment === 'top-left') {
        vPos = 'start';
        hPos = 'start';
    }
    else if (props.alignment === 'top-center') {
        vPos = 'start';
        hPos = 'center';
    }
    else if (props.alignment === 'top-right') {
        vPos = 'start';
        hPos = 'end';
    }
    else if (props.alignment === 'middle-left') {
        vPos = 'center';
        hPos = 'start';
    }
    else if (props.alignment === 'middle-center') {
        vPos = 'center';
        hPos = 'center';
    }
    else if (props.alignment === 'middle-right') {
        vPos = 'center';
        hPos = 'end';
    }
    else if (props.alignment === 'bottom-left') {
        vPos = 'end';
        hPos = 'start';
    }
    else if (props.alignment === 'bottom-center') {
        vPos = 'end';
        hPos = 'center';
    }
    else if (props.alignment === 'bottom-right') {
        vPos = 'end';
        hPos = 'end';
    }
    const direction = props.direction === 'row' ? 'row' : 'column';
    if (direction === 'row') {
        style.justifyContent = hPos;
        style.alignItems = vPos;
    }
    else if (direction === 'column') {
        style.justifyContent = vPos;
        style.alignItems = hPos;
    }
    return style;
}
