import { RuiReactComponentProps } from '@roc-digital/rich-ui';

export function GenericElement(props: RuiReactComponentProps) {
  const data = props.node.data;

  if(props.node.element === 'text') {
    //	&nbsp;
    if(props.node.data?.content === ' ') {
      props.node.data.content = "\u00A0"; 
    }
    return drawTextElement(data?.style || {}, props.node.data?.content, props.children);
  }

  if(props.node.element === 'line-break') {
    return <br/>;
  }

  return drawLayoutElement(data?.style || {}, props.children);

}

function drawTextElement(style: any, text?: string, children?: any) {
  if(text && !children && Object.keys(style || {}).length === 0) return text as any as JSX.Element;

  return <span
    style={{
      fontWeight: style.fontWeight,
      fontStyle: style.fontStyle,
      color: style.color,
    }}
  >{text}{children}</span>
}

function drawLayoutElement(style: any, children?: any) {
  return <span
    style={{
      display: 'flex',
      gap: style.gap,
      alignContent: style.alignContent,
      marginTop: style.marginTop,
      marginLeft: style.marginLeft,
      marginRight: style.marginRight,
      marginBottom: style.marginBottom,
      paddingTop: style.paddingTop,
      paddingLeft: style.paddingLeft,
      paddingRight: style.paddingRight,
      paddingBottom: style.paddingBottom,
    }}
  >
    {children}
  </span>
}

