import { memo, useEffect } from 'react';
import AppFooter from '@/components/elements/AppFooter';

import { RenderUiBlock } from '@/components/elements/RenderUiBlock';

const Terms = memo(() => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return <div className="h-full flex flex-col justify-center bg-gray-100 dark:bg-surface-dark dark:text-white font-chivo overflow-x-hidden">
    <div className='p-1 sm:p-5 mt-10 mb-20 mx-auto max-w-5xl'>
      <RenderUiBlock blockId='sys-tos-page' />
    </div>
    <AppFooter />
  </div>;
});

Terms.displayName = 'Terms and Conditions';

export default Terms;
