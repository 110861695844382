import { RuiReactComponentProps, EmbedAttributes } from '@roc-digital/rich-ui';
import { TwitterTweetEmbed } from '../web/Twitter';
import { YouTube } from '../web/Youtube';
import { Rumble } from '../web/Rumble';

import React from 'react';

export function EmbedElement(props: RuiReactComponentProps) {
  let data: Partial<EmbedAttributes> | undefined = props.node.data;
  return <Embed url={data?.url}/>
}

export interface EmbedProps {
  url?: string;
}

export function Embed(props: EmbedProps) {
  const embed = React.useMemo(() => readEmbedUrl(props.url), [props.url]);

  if (!embed?.type) {
    return <div className={'w-full h-full flex items-center'}>
      Unknown Embed URL
    </div>
  }

  let fragment: JSX.Element | null = null;


  if (embed.type === 'rumble') {
    fragment = <Rumble id={embed.id} />;
  }

  if (embed.type === 'tweet') {
    fragment = <TwitterTweetEmbed tweetId={embed.id} options={{ height: 300 }} />;
  }

  if (embed.type === 'youtube') {
    fragment = <YouTube videoId={embed.id} opts={{width: '100%', playerVars: { autoplay: 0, }, }} className="w-full" />;
  }

  if (!embed?.type) {
    fragment = <>Unknown Embed URL</>;
  }

  return <div className={'w-full h-full flex items-center'}>
    {fragment}
  </div>
}

interface EmbedData {
  id: string;
  type: 'tweet' | 'youtube' | 'rumble'
}

function readEmbedUrl(url?: string): EmbedData | null {
  if (!url) return null;

  if (isTweet(url)) {
    const regex = /\/(\d+)(?:\?s=\d+)?$/;
    const tweetId: any = url.match(regex);
    return {
      id: tweetId[1],
      type: 'tweet'
    }
  }
  if (isYoutube(url)) {
    const regex = /(?:youtube\.com\/(?:embed\/|watch\?v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/;
    const videoId: any = url.match(regex);
    return {
      id: videoId[1],
      type: 'youtube'
    }
  }
  if (isRumble(url)) {
    const urlSections = url?.split('/');
    return {
      id: urlSections[1],
      type: 'rumble'
    }
  }
  return null;
};

const isTweet = (url: string) => {
  const regex = /\b(https:\/\/x\.com|https:\/\/twitter\.com)\/\S*/i; ///(^|\s)(https:\/\/)?(x\.com|twitter\.com\/\w+\/status\/\d+)($|\s)/i;
  return regex.test(url);
};
const isYoutube = (url: string) => url.includes('youtube.com');
const isRumble = (url: string) => url.includes('rumble.com');