import React, { useEffect } from "react"

export type UseMethodExecution<T extends ((...args: any[]) => Promise<any>)> = {
  result: Awaited<ReturnType<T>>,
  args: Parameters<T>,
};

export interface UseMethodOptions<T extends ((...args: any[]) => Promise<any>)> {
  onSuccess?: (result: Awaited<ReturnType<T>>, args: Parameters<T>) => void;
  onBefore?: (args: Parameters<T>) => Promise<Parameters<T> | undefined>;
  onError?: (error: any, args: Parameters<T>) => void;
  map?: (next: UseMethodExecution<T>, current?: UseMethodExecution<T> ) => UseMethodExecution<T>,
  defaultValue?: Awaited<ReturnType<T>>;
  autoRun?: Parameters<T>;
  ssrPreloadKey?: string;
  loading?: boolean;
}

/**
 * Converts an asynchronous method into a React hook. Breaks out the following states that are often needed
 * when calling asynchronous methods from within a React component.
 * 
 * - result: The last value returned from the method
 * - loading: The boolean loading state of the method
 * - count: The amount of times the method has been called
 * - debounce: Execute the method, but debounce it per the period given to useMethod.
 * - run: Immediately execute the method without debouncing it
 * - error: The error, if any, from the last method execution.
 * 
 */
export function useMethod<T extends ((...args: any[]) => Promise<any>)>(method: T, options?: UseMethodOptions<T>) {

  const ssrPreloadValue = getSsrValue(options?.ssrPreloadKey);

  const [result, setResult] = React.useState<Awaited<ReturnType<T>> | undefined>(ssrPreloadValue || options?.defaultValue);
  const [error, setError] = React.useState<any>();
  const [loading, setLoading] = React.useState(options?.loading || false);

  const state = React.useRef({
    timer: 0 as any,
    loading: false,
    promise: null as any as Promise<Awaited<ReturnType<T>>>,
    res: null as any as (value: any) => void,
    rej: null as any as (value: any) => void,
    count: 0,
    options,
    lastResult: null as any as Awaited<ReturnType<T>>,
    lastArgs: undefined as any as Parameters<T> | undefined
  })

  state.current.options = options;

  const reset = () => {
    if(state.current.timer) {
      clearTimeout(state.current.timer);
    }

    if(!state.current.promise) {
      state.current.promise = new Promise((res, rej) => {
        state.current.res = res;
        state.current.rej = rej;
      });
      setLoading(true);
    }
  };

  const close = () => {
    if(state.current.timer) {
      clearTimeout(state.current.timer);
    }
    state.current.count = state.current.count + 1;
    setLoading(false);
  }
  
  const exec = async (...args: Parameters<T>) => {
    const currentCount = state.current.count + 1;
    state.current.count = currentCount;
    setError(null);
    const lastArgs = state.current.lastArgs;
    state.current.lastArgs = args;

    const ssrValue = takeSsrValue(state.current.options?.ssrPreloadKey);

    if(ssrValue) {
      state.current.lastResult = ssrValue;
      state.current.promise = null as any;

      if(state.current.options?.onSuccess) {
        state.current.options?.onSuccess(ssrValue, args);
      }

      setLoading(false);
      setResult(ssrValue);
      state.current.res(ssrValue);

      return;
    }

    method(...args)
    .then((v) => {
      if(currentCount !== state.current.count) {
        // Method was called mid-request. Ignore it.
        // It would be nice to cancel the request.
        return;
      }

      if(options?.map) {
        const current = state.current.lastResult ? {args: lastArgs || [], result: state.current.lastResult} : undefined;
        const exec = options?.map({args, result: v}, current as any);

        state.current.lastResult = exec.result;
        v = state.current.lastResult;
      }

      state.current.lastResult = v;
      state.current.promise = null as any;

      if(state.current.options?.onSuccess) {
        state.current.options?.onSuccess(v, args);
      }

      setLoading(false);
      setResult(v);
      state.current.res(v);
    })
    .catch((e) => {
      if(currentCount !== state.current.count) {
        // Method was called mid-request. Ignore it.
        // It would be nice to cancel the request.
        return;
      }

      if(state.current.options?.onError) {
        state.current.options?.onError(e, args);
      }

      state.current.promise = null as any;
      setLoading(false);
      setError(e);
      state.current.rej(e);
    })
  }


  const debounce = React.useCallback(async (args: Parameters<T>, period = 500) => {
    const onBefore = state.current.options?.onBefore || (async (args) => args);

    let fromBeforeArgs = await onBefore(args);

    if(fromBeforeArgs instanceof Array) {
      args = fromBeforeArgs as any;
    } else {
      return;
    }

    if(!args) return;

    reset();
    state.current.timer = setTimeout(() => {
      exec(...args)
    }, period);

    return state.current.promise;
  }, [method]);

  const run = async (...args: Parameters<T>) => {
    const onBefore = state.current.options?.onBefore || (async (args) => args);

    let fromBeforeArgs = await onBefore(args);

    if(args instanceof Array) {
      args = fromBeforeArgs as any;
    } else {
      return;
    }

    if(!args) {
      return;
    }

    reset();
    exec(...args);
    return state.current.promise;
  };

  const reload = async () => {
    let args = state.current.lastArgs;
    if(!args) return;

    const onBefore = state.current.options?.onBefore || (async (args) => args);

    let fromBeforeArgs = await onBefore(args);

    if(args instanceof Array) {
      args = fromBeforeArgs as any;
    } else {
      return;
    }

    if(!args) {
      return;
    }

    reset();
    exec(...args);
    return state.current.promise;
  };

  const getLastResult = React.useCallback(() => state.current.lastResult || options?.defaultValue as Awaited<ReturnType<T>>, [options?.defaultValue]);

  useEffect(() => {
    if(!options?.autoRun || !(options?.autoRun instanceof Array)) {
      return;
    }

    debounce(options?.autoRun, 60).catch(() => {});
  }, options?.autoRun || []);

  return {
    debounce,
    run,
    reload,
    loading: ssrPreloadValue ? false : loading,
    error,
    result: (result || options?.defaultValue) as Awaited<ReturnType<T>>,
    count: state.current.count,
    setResult: (value: Awaited<ReturnType<T>>) => {
      state.current.lastResult = value;
      setResult(value);
    },
    getLastResult,
    lastArgs: state.current.lastArgs,
    close
  };
}

/**
 * Returns a SSR value or null.
 */
function getSsrValue(ssrPreloadKey?: string) {
  return  window?.['ssrPreload']?.[ssrPreloadKey || '_null'] || null;
}

/**
 * Takes a SSR, removes it from globals and returns it.
 * 
 * This will only return the value once.
 */
function takeSsrValue(ssrPreloadKey?: string) {
  const key = ssrPreloadKey || '_null';
  const value =  window?.['ssrPreload']?.[key] || null;

  if(value) {
    window['ssrPreload'][key] = null;
  }

  return value;
}