// eslint-disable-next-line import/no-cycle
import React from 'react';
import { initializeApp } from '@firebase/app';
import {
  getAuth,
  signInWithEmailAndPassword,
  getIdTokenResult,
  updateEmail,
  updatePassword,
  signInAnonymously,
  createUserWithEmailAndPassword,
  signOut,
  signInWithPopup,
  GoogleAuthProvider,
  FacebookAuthProvider,
  TwitterAuthProvider,
  OAuthProvider,
  sendPasswordResetEmail,
} from '@firebase/auth';

import { publish } from '@roc-digital/mxm-base/events';
import { SessionState } from '@roc-digital/mxm-base/state';
import { getConfig } from '@roc-digital/mxm-base/config';
import { Nucleus, logError, logInfo, readProfile, reportMxMAnalytics, setGetTokenHook } from '@roc-digital/mxm-base/logic';
import { getEnvJson } from './env';
import { reportSignupFromUtmUrl } from './analytics';
import { setProfile } from '@roc-digital/ui-lib';

export const firebaseConfig = getEnvJson<{
  apiKey: string,
  authDomain: string,
  projectId: string,
  storageBucket: string,
  messagingSenderId: string,
  appId: string,
  measurementId: string,
  vapidKey: string,
}>('firebaseConfig', 'Invalid firebase config.');

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

let initPromiseRes = () => {};
let initPromise: Promise<void> | null = new Promise<void>(res => initPromiseRes = res);

auth.onAuthStateChanged(user => {
  if(user) {
    updateState({
      authenticated: user?.isAnonymous !== true,
      loading: false,
      isAnonymous: user?.isAnonymous
    })
  } else {
    updateState({authenticated: false, loading: false, isAnonymous: true, onBoarded: false})
  }
  initPromise = null;
  initPromiseRes();
});

auth.onIdTokenChanged(async (user) => {
  if(user) {
    getIdTokenResult(user)
    .then((fbtoken) => SessionState.setToken(fbtoken.token))
    .catch(error => console.error(error))
  }
}, (error) => {
  console.log(error)
});

export function getApp() {
  return app;
}

export function getFirebaseAuth() {
  return auth;
}

export interface OAuthState {
  authenticated: boolean;
  loading: boolean;
  authenticating?: boolean;
  isAnonymous: boolean;
  onBoarded?: boolean;
}

const state: OAuthState = {
  authenticated: false,
  loading: true,
  isAnonymous: true,
}
export const events = new EventTarget();

export function getState() {
  return state;
}

export function updateState(next: Partial<OAuthState>) {
  Object.assign(state, next);
  events.dispatchEvent(new Event('next'));
}

export async function lookupAccount() {
  return {
    onBoarded: false
  }
}

setGetTokenHook(async () => {
  if(initPromise) {
    await initPromise;
  }

  if(!auth.currentUser) {
    return '';
  }

  const token = await auth.currentUser?.getIdToken();

  return token || '';
});

export function useAuthState() {
  const [current, setNext] = React.useState(state);
  React.useEffect(() => {
    const cb = () => {
      setNext({...state});
    };

    events.addEventListener('next', cb);

    return () => {
      events.removeEventListener('next', cb);
    }
  });

  return current;
}

export interface PasswordSignInOptions {
  method: 'password-sign-in';
  email: string;
  password: string;
}

export interface PasswordSignupOptions {
  method: 'password-signup';
  email: string;
  password: string;
  username?: string;
}

export interface SocialLoginOptions {
  method: 'social';
  app: 'google' | 'facebook' | 'twitter' | 'apple';
}

export interface AnonymousOptions {
  method: 'anonymous';
}

export type LoginOptions = PasswordSignInOptions | PasswordSignupOptions | SocialLoginOptions | AnonymousOptions;

export async function LoginAndCheckAccount(options: LoginOptions) {
  const result = await login(options);
  const account = await readProfile().catch(() => ({} as any));

  setProfile(account);

  if(account?.tags?.length === 0) {
    Nucleus.signup();
    reportSignupFromUtmUrl();
  } else {
    Nucleus.login();
  }
  
  updateState({onBoarded: account?.tags?.length ? true : false });

  return {
    ...result,
    account,
    onBoarded: account?.tags?.length ? true : false,
  }
}

/**
 * Attempts a login, or signup, via Firebase.
 * 
 * Catches Firebase Auth errors and converts them into user Friendly error messages.
 */
export async function login(options: LoginOptions) {
  try {
    const userCredentials = await loginSwitch(options);

    if(!userCredentials) {
      throw new Error('Something went wrong.')
    }
  
    const created = new Date(parseInt(userCredentials?.user?.metadata?.creationTime || '0'));
    const now = Date.now();
  
    return {
      isAnonymous: userCredentials.user.isAnonymous,
      isNewAccount: (now - (1000 * 300)) > created.getTime(),
      user: userCredentials.user,
      provider: userCredentials.providerId || '',
      loginOptions: options
    }
  } catch(err: any) {

    if(err.code === 'auth/email-already-exists' || err.code == 'auth/email-already-in-use') {
      throw new Error('An account with this email already exists.');
    } else if(err.code === 'auth/wrong-password') {
      throw new Error('Incorrect password.');
    } else if(err.code === 'auth/user-not-found') {
      throw new Error('Account does not exist.');
    } else if(err.code === 'auth/invalid-email') {
      throw new Error('Invalid email address.');
    } else if(err.code === 'auth/missing-password') {
      throw new Error('Missing password');
    } else if(err.toString().toLowerCase().includes('cancelled')
      || err.toString().includes('canceled')
      || err.code === 'auth/popup-closed-by-user'
    ) {
      throw new Error('Login cancelled');
    }

    console.error(err);
    throw new Error('Unknown error. Failed to sign in.');
  }
}

/**
 * Switches of the login method running the requested method.
 **/
async function loginSwitch(options: LoginOptions) {
  if(options.method === 'social') {
    return await loginWithSocialApp(options)
  }

  const auth = getAuth();

  if(options.method === 'anonymous') {
    return await signInAnonymously(auth)
  } else if(options.method === 'password-signup') {  
    return await createUserWithEmailAndPassword(auth, options.email, options.password)
  } else if(options.method === 'password-sign-in') {
    return await signInWithEmailAndPassword(auth, options.email, options.password)
  } else {
    throw new Error('Unknown sign-in method.');
  }
}

async function loginWithSocialApp(options: SocialLoginOptions) {
  if(options.app === 'google') {
    return await signInWithGoogle();
  } else if(options.app === 'apple') {
    return await signInWithApple();
  } else if(options.app === 'facebook') {
    return await signInWithFacebook();
  } else if(options.app === 'twitter') {
    return await signInWithTwitter();
  } else {
    throw new Error('Unknown social sign-in provider');
  }
}

async function signInWithGoogle() {
  const auth = getAuth();
  const provider = new GoogleAuthProvider();
  return await signInWithPopup(auth, provider);
}

async function signInWithFacebook() {
  const auth = getAuth();
  const provider = new FacebookAuthProvider();
  provider.addScope('email')
  return await signInWithPopup(auth, provider);
}

async function signInWithTwitter() {
  const auth = getAuth();
  const provider = new TwitterAuthProvider();
  return await signInWithPopup(auth, provider);
}

async function signInWithApple() {
  const auth = getAuth();
  const provider = new OAuthProvider('apple.com');
  return await signInWithPopup(auth, provider);
}



/// OLD -- needs cleanup


export function signoutWithFirebase(sessionState: typeof SessionState): Promise<void> {
  if (getConfig().mocking) {
    sessionState.clear();
    publish('mxm.auth', 'signout');
    return Promise.resolve();
  }

  return signOut(auth)
    .then(() => {
      sessionState.clear();
      publish('mxm.auth', 'signout');
    })
    .catch((error) => {
      sessionState.clear();
      publish('mxm.auth', 'signout');
      logError('signoutWithFirebase', error);
    });
}

export function deleteUserWithFirebase(): Promise<void> {

  if (getConfig().mocking) {
    publish('mxm.http.firebase', 'deleteUserWithFirebase.sucess');
  }

  const user = auth.currentUser;

  if (!user) {
    return Promise.reject(new Error('No user logged in'));
  }

  return user
    .delete()
    .then(() => {
      publish('mxm.http.firebase', 'deleteUserWithFirebase.success');
    })
    .catch((error: unknown) => {
      publish('mxm.http.firebase', 'deleteUserWithFirebase.failed', error);
      throw error;
    });
}

export function updateEmailWithFirebase(email: string): Promise<void> {

  if (getConfig().mocking) {
    publish('mxm.http.firebase', 'updateEmailWithFirebase.success', { email });
  }

  const user = auth.currentUser;

  if (!user) {
    return Promise.reject(new Error('No user logged in'));
  }

  return updateEmail(user, email)
    .then(() => {
      publish('mxm.http.firebase', 'updateEmailWithFirebase.success', { email });
    })
    .catch((error: unknown) => {
      publish('mxm.http.firebase', 'updateEmailWithFirebase.failed', error);
      throw error;
    });
}

export function updatePasswordWithFirebase(password: string): Promise<void> {
  logInfo('[firebase.updatePasswordWithFirebase]', { password });

  const user = auth.currentUser;

  if (!user) {
    return Promise.reject(new Error('No user logged in'));
  }

  return updatePassword(user, password)
    .then(() => {
      publish('mxm.http.firebase', 'updatePasswordWithFirebase.success', { password });
    })
    .catch((error: unknown) => {
      publish('mxm.http.firebase', 'updatePasswordWithFirebase.failed', error);
      throw error;
    });
}

export function resetPasswordWithFirebase(username: string): Promise<void> {
  if (getConfig().mocking) {
    publish('mxm.http.firebase', 'updatePasswordWithFirebase', { username });
  }

  const auth = getAuth();
  return sendPasswordResetEmail(auth, username)
    .then(() => {
      publish('mxm.http.firebase', 'updatePasswordWithFirebase', { username });
    });
}
