import { RenderUiBlock, RenderUiBlockProps } from './RenderUiBlock';
import { usePromotional } from '@/logic/promotionals';
import { Promotional } from '@roc-digital/types';

export interface PromoUiBlockProps {
  block: keyof Promotional;
  errorContent?: RenderUiBlockProps['errorContent'];
  onAction?: RenderUiBlockProps['onAction'];
  getTextToken?: (p: Promotional) => any;
}

export function PromoUiBlock(props: PromoUiBlockProps) {
  const {promotional} = usePromotional();

  return <RenderUiBlock
    blockId={promotional?.[props.block] as any}
    errorContent={props.errorContent}
    textTokens={{monthlyPrice: '$9.28'}}
    onAction={props.onAction}
  />
}
