import { JsonFetch } from "@roc-digital/mxm-base/logic";
import { ContentBlock } from "@roc-digital/rich-ui";
import { createState } from './createState';
import { getEnv } from "./env";

interface BlocksState {
  block?: ContentBlock;
  loading: boolean;
  loaded: boolean;
}

const ALL_BLOCKS: {[id: string]: ReturnType<typeof createState<BlocksState>>} = {};

export async function readBlock(id: string) {
  return JsonFetch<ContentBlock>({url: `${getEnv().auxApi}/ui-blocks/${id}`});
}

export function useBlock(id: string) {
  if(!ALL_BLOCKS[id]) {
    const state = createState<BlocksState>({loaded: false, loading: true});
    ALL_BLOCKS[id] = state;
    readBlock(id).then((block) => {
      state.set({loaded: true, loading: false, block});
    }).catch((err) => {
      console.error(err);
      state.set({loaded: true, loading: false});
    });
  }

  const state = ALL_BLOCKS[id];
  return state.use();
}