import { readBlock } from "@/logic/readBlock";
import { RuiContext, Render } from "@roc-digital/rich-ui";
import { useMethod, useProfile } from "@roc-digital/ui-lib";
import { Icon, getWebComponent } from '@roc-digital/ui-web';

import React from "react";
import './RenderUiBlock.css';
import { getEnv } from "@/logic/env";

export interface RenderUiBlockProps {
  blockId?: string;
  onAction?: RuiContext['doAction'];
  textTokens?: RuiContext['textTokens'];
  errorContent?: () => any;
}

const editUrl = getEnv().adminApp;

export function RenderUiBlock(props: RenderUiBlockProps) {
  const components = React.useMemo(getWebComponent, []);
  const query = useMethod(readBlock, {
    autoRun: props.blockId ? [props.blockId] : [''],
    onBefore: async (args) => {
      if(!args[0]) return
      return args;
    }
  });

  const isAdmin = useProfile()?.role === 'admin'
  const [display, setDisplay] = React.useState<'draft' | 'published'>('draft');

  if(query.error && props.errorContent) return props.errorContent();

  if(!query.result) return null;

  const draft = query.result?.draft?.children?.length ? true : false;
  const published = query.result?.document?.children?.length ? true : false;

  return <div className={'flex flex-col font-chivo relative ' + (isAdmin ? 'admin-rui-block' : '')}>
    <Render
      block={query.result}
      onAction={props.onAction}
      components={components}
      display={isAdmin ? display : 'published'}
      textTokens={props.textTokens}
    />
    {isAdmin ? <AdminPanel
      display={display}
      draft={draft}
      published={published}
      onToggleDisplay={() => setDisplay(v => v === 'draft' ? 'published' : 'draft')}
      onEdit={() => window.open(`${editUrl}/blocks/${query.result?.id}`)}
    /> : null}
  </div>
}

interface AdminPanelProps {
  onEdit?: () => void;
  draft?: boolean;
  published?: boolean;
  display?: 'draft' | 'published';
  onToggleDisplay?: () => void;
}

function AdminPanel(props: AdminPanelProps) {
  const activeStyle = {color: 'green', fontSize: '16px'};
  return <div className="panel">
    <span className="item enabled" style={{padding: '0px 8px'}} onClick={props.onToggleDisplay}>
      {props.draft ? <span style={props.draft && props.display === 'draft' ? activeStyle : {}}>DRAFT</span> : null}
      {props.draft && props.published ? <span>|</span> : null}
      {props.published ? <span style={props.published && props.display === 'published' ? activeStyle : {}}>PUBLISHED</span> : null}
    </span>
    
    <span className="item" onClick={props.onEdit}>
      <Icon src={editIcon} size="small"/>
    </span>
  </div>
}

const editIcon = `<svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_87_49955)">
<path d="M15.5509 2.72387L17.9863 0.288452L21.7121 4.0142L19.2767 6.44962L15.5509 2.72387Z" fill="white"/>
<path d="M0.909654 17.3648L0.288635 21.7116L4.63543 21.0905L18.2303 7.49565L14.5046 3.7699L0.909654 17.3648Z" fill="white"/>
</g>
<defs>
<clipPath id="clip0_87_49955">
<rect width="22" height="22" fill="white"/>
</clipPath>
</defs>
</svg>
`