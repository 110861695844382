import React from 'react';
export function render(doc, components, context) {
    const renderChildren = (children, parent) => {
        if (!children?.length)
            return [];
        return children.map((node, i) => {
            const element = components[node.element] || components['*'] || (() => null);
            const props = {
                node,
                parent,
                context,
                key: i
            };
            return React.createElement(element, props, ...renderChildren(node.children, node));
        });
    };
    return renderChildren(doc.children);
}
