import React, { useState } from "react";
import { useStripe, useElements, CardElement } from "@stripe/react-stripe-js";
import { createSubscription } from "@/logic/payments";
import { Button, ButtonSizes } from "@roc-digital/ui-web";
import { processSubscription } from "@roc-digital/mxm-base/logic";
import { usePromotional } from "@/logic/promotionals";

export interface CheckoutFormProps {
  onComplete?: () => void;
  coupon?: string;
  defaultPlan?: 'annual' | 'monthly',
  preCheck?: () => boolean;
}

const MONTHLY_PLAN = 'price_1OSQL6KojUbL0u4hU9aBC3aF';
const ANNUAL_PLAN = 'price_1OSQL6KojUbL0u4hDQ0xb5hP';

const CheckoutForm = (props: CheckoutFormProps) => {
  const promotional = usePromotional();
  const coupon = promotional?.promotional?.stripe_coupon || props.coupon;
  const [error, setError] = useState<any>(null);
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const stripe = useStripe();
  const elements = useElements();
  //TODO move this to env variable or API
  const [plan, setPlan] = useState<any>(() => {
    if(document.URL.includes('qa.mxmnews.io')) {
      return 'price_1O4ammCI88K9NYrQdHkOkxVF'
    }
    if(props.defaultPlan === 'annual') {
      return ANNUAL_PLAN;//'price_1OSQL6KojUbL0u4hDQ0xb5hP';
    } else {
      return MONTHLY_PLAN
    }
  });

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    if (!stripe || !elements) {
      return;
    }

    const cardElement: any = elements.getElement(CardElement);

    if(props.preCheck && (await props.preCheck()) === false) {
      return;
    }

    setLoading(true)
    try {
      const { error, paymentMethod } = await stripe.createPaymentMethod({
        type: "card",
        card: cardElement,
      });
      // console.log(event.email);

      if (error) {
        setError(error.message);
        setLoading(false);
        return;
      }

      const { id } = paymentMethod;

      await processSubscription('stripe', {
        plan: plan,
        stripeToken: id,
        coupon: coupon
      });

      if(props.onComplete) {
        props.onComplete();
      } else {
        setTimeout(() => {
          location.reload();
        }, 2000);
      }

      setSuccess(true);
    } catch (error: any) {
      setError(error.message);
    }

    setLoading(false);
  };

  return (
    <form onSubmit={handleSubmit}>
      <select name="plan" value={plan} onChange={(e) => setPlan(e.target.value)} required className="w-full p-2 rounded-md mt-2 mb-2">
        <option value={MONTHLY_PLAN}>Monthly Subscription ($9.96/mo)</option>
        <option value={ANNUAL_PLAN}>Annual Subscription ($89.64/year)</option>
      </select>
      <CardElement className="p-2" onChange={() => setError('')}/>
      {error && <p style={{ color: "red" }}>{error}</p>}
      {success && <p style={{ color: "green" }}>Subscription created!</p>}
      <Button
        clickEvent={handleSubmit}
        disabled={!stripe || loading}
        customClassName="mt-6"
        loading={loading}
        size={ButtonSizes.largeFull}
      >
        Subscribe
      </Button>
    </form>
  );
};

export default CheckoutForm;