import { JsonFetch } from "@roc-digital/mxm-base/logic";
import { Promotional } from "@roc-digital/types";
import { createState } from './createState';
import { getEnv } from "./env";

const DEFAULT_PROMOTIONAL = 'default';

interface PromotionalState {
  promotional?: Promotional;
  loading: boolean;
  loaded: boolean;
}
const STATE = createState<PromotionalState>({loaded: false, loading: true});

export async function loadPromotional() {
  const id = findPromotionalId();

  // @ts-ignore
  const ssrLoadedPromotionals: {[id: string]: Promotional} = window['promotionals'] || {};
  
  if(ssrLoadedPromotionals[id]) {
    STATE.set({
      loaded: true,
      loading: false,
      promotional: ssrLoadedPromotionals[id]
    });
  } else {
    STATE.set({loading: true});
    const promotional = await readPromotional(id);
    STATE.set({
      loaded: true,
      loading: false,
      promotional: promotional
    });
  }
}

export async function readPromotional(id: string) {
  return JsonFetch<Promotional>({ url: `${getEnv().auxApi}/promotionals/${id}` });
}

function findPromotionalId() {
  const locate = () => {
    const url = new URL(location.toString());
    const promotionalQuery = url.searchParams.get('promotional');
    if (promotionalQuery) {
      sessionStorage.setItem('promotional', promotionalQuery);
      return promotionalQuery;
    }

    if (sessionStorage.getItem('promotional')) {
      return sessionStorage.getItem('promotional');
    }
  }

  return locate() || DEFAULT_PROMOTIONAL
}

export function usePromotional() {
  return STATE.use();
}
