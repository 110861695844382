import React from 'react';
import { render } from './render';
export function Render(props) {
    const content = React.useMemo(() => {
        const context = {
            doAction: props.onAction || ((...args) => {
                console.warn("Nothing hooked up to accept action", args);
            }),
            textTokens: props.textTokens,
        };
        let doc = props.block?.draft?.children?.length ? props.block?.draft : props.block?.document;
        if (props.display === 'published' && props.block?.document?.children?.length) {
            doc = props.block?.document;
        }
        else if (props.display === 'draft' && props.block?.draft?.children?.length) {
            doc = props.block?.draft;
        }
        if (!doc?.children?.length)
            return null;
        return render(doc, props.components, context);
    }, [props.block, props.display, props.textTokens]);
    return content;
}
